<template>
  <div class="navigator">
    <div class="stage" :class="{active: stage === 'STAGE1'}">
      <div v-if="isDoneStage1">
        <img src="~@/assets/images/img_status_done.svg"/>
      </div>
      <div v-else class="circle">1</div>
      <div class="header-text">{{ $t('navigator.introduction') }}</div>
    </div>
    <div class="bar" :class="{active: isProgress || isDoneStage1}"></div>
    <div class="stage" :class="{active: stage === 'STAGE2'}">
      <div v-if="isDoneStage2">
        <img src="~@/assets/images/img_status_done.svg"/>
      </div>
      <div v-else class="circle">2</div>
      <div class="header-text">{{ $t('navigator.upload') }}</div>
    </div>
    <div class="bar" :class="{active: isProgress || isDoneStage2}"></div>
    <div class="stage" :class="{active: stage === 'STAGE3' || isProgress }">
      <div v-if="isDelivery && !isTrialLoading">
        <img src="~@/assets/images/img_status_done.svg"/>
      </div>
      <div v-else class="circle">3</div>
      <div class="header-text">{{ $t('navigator.create_my_trial') }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { progressType } from '@/assets/js/code';

export default {
  name: 'navigator',
  props: {
    stage: {
      type: String, // STAGE1, STAGE2, STAGE3
      required: true,
    },
  },
  data() {
    return {
      progressType,
    };
  },
  computed: {
    ...mapGetters(['trialSurvey', 'isTrialLoading']),
    isDoneStage1() {
      if (this.trialSurvey.progress === this.progressType.CREATING) {
        if (['STAGE2', 'STAGE3'].includes(this.stage)) return true;
        return false;
      }
      if (this.stage === 'STAGE1') return false;
      return true;
    },
    isDoneStage2() {
      if (this.trialSurvey.progress === this.progressType.CREATING) {
        if (['STAGE3'].includes(this.stage)) return true;
        return false;
      }
      if (this.stage === 'STAGE2') return false;
      return true;
    },
    isProgress() {
      return this.trialSurvey.progress !== this.progressType.CREATING;
    },
    isDelivery() {
      return ![progressType.CREATING, progressType.TEMP_CREATING_DONE]
        .includes(this.trialSurvey.progress);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.navigator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 23px;

  .stage {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $font_size_14;
      line-height: 1.5;
      letter-spacing: -0.7px;
      color: #4a4a4a;
      width: 39px;
      height: 39px;
      padding: 10px 15px 10px 16px;
      border: solid 4px #dadce0;
      border-radius: 50%;
      background-color: #ffffff;
    }

    .header-text {
      position: absolute;
      bottom: -23px;
      font-size: $font_size_14;
      line-height: 19px;
      letter-spacing: -0.7px;
      color: #4a4a4a;
      white-space: nowrap;
    }

    &.active {
      .circle {
        border: solid 4px #00ca94;
      }
    }
  }

  .bar {
    width: 70px;
    height: 5px;
    margin: 17px 5px;
    border-radius: 50px;
    background-color: #dadce0;

    &.active {
      background-color: #00ca94;
    }
  }
}
</style>
