<template>
  <div class="trial-survey-upload">
    <div class="survey-upload">
      <div class="upload-item-container">
        <span class="survey-guide-text">{{ $t('survey_upload.text_download') }}</span>
        <button class="template-download-button" @click="clickDownload()">
          {{ $t('survey_upload.button_download') }}
        </button>
      </div>
      <div class="upload-item-container">
        <span class="survey-guide-text">{{ $t('survey_upload.text_update_file') }}</span>
        <div id="drag_drop_app" class="file_uploader_module">
          <input type="file" ref="file_upload" @change="onFileChange" name="excelFile">
          <div class="drop_area" @dragenter="dragEnter" @dragleave="dragLeave" @dragover.prevent
               @drop.prevent="dropFile"
               :class="{enter: isEnter}" type="button" @click="$refs.file_upload.click()">
            <div class="drag-drop-guide-container">
              <div class="drag-drop-icon"/>
              <span class="drag-drop-guide-text">Drag & Drop</span>
              <span class="drag-drop-guide-text">or</span>
              <button class="file-find-button">{{ $t('survey_upload.button_update') }}</button>
            </div>
          </div>
        </div>
        <div v-if="uploadFile || isUploadCompleted"
             class="uploaded-file-container">
          <div class="survey-upload-info-container">
            <span class="survey-upload-file-name">{{ filename }}</span>
            <div class="survey-upload-file-delete" @click="deleteUploadedFile"/>
          </div>
          <div class="survey-upload-progress-container">
            <KProgress type="line" :color="progressColor" :show-text="false"
                       :percent="uploadPercentage" class="survey-upload-progress"/>
            <span class="survey-upload-status-text">{{ uploadStatus }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="button-group">
      <button class="secondary before" @click="$emit('before')">
        <span>{{ $t('back') }}</span>
      </button>
      <button class="next" :disabled="!isUploadCompleted"
              @click="nextStage">
        <span>{{ $t('next') }}</span>
        <img class="next-image" src="~@/assets/images/ico_btn_next.svg"/>
      </button>
    </div>
    <div class="warning-text" v-html="textWarning"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import KProgress from 'k-progress';
import { ApiService } from '@/common/api.service';
import { progressType } from '@/assets/js/code';

export default {
  name: 'SurveyUpload',
  components: {
    KProgress,
  },
  data() {
    return {
      isEnter: false,
      uploadFile: null,
      uploadPercentage: 0,
      uploadStatus: '',
      fileName: '',
      progressColor: '#00ca94',
    };
  },
  computed: {
    ...mapGetters(['trialSurvey']),
    textWarning() {
      if (this.$i18n.locale.includes('ko')) {
        return `
        <div>
          <span>* My Trial 생성 페이지에서 </span>
          <span class="bold">My Trial 페이지 다시 생성하기</span>
          <span>버튼을 눌러</span>
        </div>
        <div>
          <span> 배포를 해주세요. </span>
          <span class="underline">배포하지 않을 시, 변경 내용이 반영되지 않습니다</span>
        </div>`;
      }
      return `
        <div>
          <span>Please click</span>
          <span class="bold">the reset my trial page  button</span>
          <span>in my trial creation page to delivery this survey.</span>
        </div>
        <div>
          <span class="underline">If the survey do not delivery, the edited will not be reflected.</span>
        </div>`;
    },
    isUploadCompleted() {
      return this.trialSurvey.uploadedFileName;
    },
    filename() {
      if (this.uploadFile) {
        return this.uploadFile.name;
      }
      return this.trialSurvey.uploadedFileName;
    },
    isDelivery() {
      return ![progressType.CREATING, progressType.TEMP_CREATING_DONE]
        .includes(this.trialSurvey.progress);
    },
  },
  async beforeMount() {
    if (this.isUploadCompleted) {
      this.uploadPercentage = 100;
      this.uploadStatus = 'Uploaded';
    }
  },
  methods: {
    ...mapActions([
      'getDefaultTrialSurveyTemplate',
      'deleteUploadedFileName',
      'modifyTempExcelData',
    ]),
    ...mapMutations(['setTrialSurveyUploadedFileName']),
    async clickDownload() {
      await this.getDefaultTrialSurveyTemplate();
    },
    dragEnter() {
      this.isEnter = true;
    },
    dragLeave() {
      this.isEnter = false;
    },
    dragOver() {
    },
    dropFile(event) {
      this.isEnter = false;
      this.sendFile(event);
    },
    async sendFile(event) {
      const files = event.target.files || event.dataTransfer.files;
      this.uploadFile = files[0];

      this.progressColor = '#00ca94';
      const formData = new FormData();
      formData.append('trialSurveyId', this.trialSurvey.id);
      formData.append('excelFile', this.uploadFile);
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (e) => {
          this.uploadPercentage = (e.loaded / e.total) * 100;
          this.uploadStatus = 'Uploading';
          if (e.loaded === e.total) {
            this.uploadStatus = 'Uploaded';
          }
        },
      };
      try {
        const { data } = await ApiService.post('/trial/uploadExcel', formData, options);
        this.setTrialSurveyUploadedFileName(data);
        this.uploadFile = null;
        this.$refs.file_upload.value = null;
      } catch (e) {
        this.uploadStatus = 'Upload error';
        this.progressColor = '#F45353';
      }
    },
    onFileChange(event) {
      this.sendFile(event);
    },
    async nextStage() {
      await this.modifyTempExcelData(this.trialSurvey.id);
      this.$emit('next');
    },
    async deleteUploadedFile() {
      this.uploadFile = null;
      await this.deleteUploadedFileName(this.trialSurvey.id);
    },
  },
};
</script>

<style lang="scss" scoped>
:root {
  --background-color: #f3f3f3;
  --background-color-hover: #b9b9b9;
  --border-color: #6d6d6d;
  --border-color-hover: #757575;
  --line-border: 2px;
  --text-color-dark: #505050;
}

.trial-survey-upload {
  display: flex;
  flex-direction: column;
  align-items: center;

  .survey-upload {
    width: 607px;
    height: 626px;
    margin-top: 48px;
    padding: 79.7px 0 115px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    border: solid 1px #dadce0;

    .upload-item-container {
      display: flex;
      align-items: center;
      flex-direction: column;

      .survey-guide-text {
        font-size: 16px;
        line-height: 1.38;
        text-align: center;
        color: #2a324b;
      }

      .template-download-button {
        width: 156px;
        height: 32px;
        padding: 7.5px 16px 8.5px;
        background-color: #00ca94;
      }

      span + button {
        margin-top: 11px;
      }

      .file_uploader_module {
        margin: 11.3px 0 0;
        width: 240px;
        height: 240px;
        border-radius: 1px;
        border: dashed 3px #DADCE0;
        background-color: #ffffff;

        & > input {
          display: none;
        }

        .drop_area {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background-color: #ffffff;
          box-sizing: border-box;
          transition: background-color 160ms ease;

          .drag-drop-guide-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .drag-drop-icon {
              width: 24px;
              height: 24px;
              background-size: 24px 24px;
              background-color: transparent;
              border: none;
              background-repeat: no-repeat;
              background-position: 0;
              background-image: url("~@/assets/images/ico-upload-file.svg");
            }

            .drag-drop-guide-text {
              font-size: 13px;
              line-height: 1;
              text-align: center;
              color: #9b9b9b;
            }

            .drag-drop-icon + .drag-drop-guide-text {
              margin-top: 7px;
            }

            .drag-drop-guide-text + .drag-drop-guide-text {
              margin-top: 14px;
            }

            .drag-drop-guide-text + .file-find-button {
              margin-top: 11px;
            }
          }
        }

        .enter {
          border: 5px dashed #b9b9b9;
          background-color: #b9b9b9;
        }
      }

      .uploaded-file-container {
        width: 360px;
        height: 56px;
        margin-top: 10px;
        padding: 10px 15px 7px 12px;
        border-radius: 3px;
        background-color: #f7f8fa;

        .survey-upload-info-container {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .survey-upload-file-name {
            font-size: 14px;
            line-height: 1.36;
            text-align: left;
            color: #2a324b;
            word-wrap: break-word;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: calc(100% - 12px);
          }

          .survey-upload-file-delete {
            width: 12px;
            height: 12px;
            background-size: 12px 12px;
            background-color: transparent;
            border: none;
            background-repeat: no-repeat;
            background-position: 0;
            background-image: url("~@/assets/images/close-black.svg");
            cursor: pointer;

            &:hover, &:active {
              background-image: url("~@/assets/images/close-green.svg");
            }
          }
        }

        .survey-upload-progress-container {
          margin-top: 3px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .survey-upload-progress {
            width: 240px;
            height: 6px;
          }

          .survey-upload-status-text {
            color: rgba(42, 50, 75, 0.4);
            font-size: 12px;
            line-height: 1.42;
            text-align: right;
          }
        }
      }
    }
  }

  .button-group {
    display: flex;
    flex-direction: row;
    margin-top: 17px;

    .before {
      margin-right: 18px;
    }

    .next {
      .next-image {
        margin-left: 10px;
        transform: rotate(90deg);
      }
    }
  }

  .warning-text {
    margin-top: 12px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: normal;
    color: #2a324b;
    text-align: center;

    .bold {
      font-weight: bold;
    }

    .underline {
      text-decoration: underline;
    }
  }
}
</style>
