<template>
  <modal name="trial-survey-intro-example-srm-modal" class="trial-survey-intro-example-modal"
    :width="1040" :height="800">
    <div class="scroll">
      <div class="wrapper">
        <img src="~@/assets/images/close-black.svg" class="close"
            @click="$modal.hide('trial-survey-intro-example-srm-modal')"/>
        <div class="header">
          <div class="header__title">Companywide Assessment</div>
          <div class="header__text">Provides Strategies for Successful Performance in the SRM Sector</div>
        </div>
        <div class="body">
          <img src="~@/assets/images/img_srm.png" alt="">
        </div>
        <div class="footer">
          <div class="footer__title">
            The path to a successful SRM Journey begins here.<br/>Let us start with a quick analysis!
          </div>
          <div>
            Learn about critical points of success in the SRM sector.
          </div>
          <div>
            there are 7 main areas for evaluation, each consisting of 2 questions. In just 10 minutes, understand the current state and develop actionable strategies.
          </div>
          <button class="large">Start the survey</button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'TrialSurveyIntroExampleSRMModal',
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';
@import '~@/assets/style/components/sally-modal.scss';

.trial-survey-intro-example-modal {
  .scroll {
    overflow: auto;
    height: 100%;
    width: 100%;
    .wrapper {
      position: relative;
      width: 100%;
      padding: 48px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .close {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
      }
      .header {
        text-align: center;

        &__title {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 20px;
        }

        &__text {
          font-weight: bold;
        }
      }
      .body {
        margin-top: 30px;
        margin-bottom: 30px;
        text-align: center;
      }
      .footer {
        text-align: center;
        font-size: 16px;
        max-width: 530px;
        width: 100%;
        margin: 0 auto;

        &__title {
          font-weight: bold;
        }

        div + div {
          margin-top: 20px;
        }

        button {
          font-size: 16px;
          margin: 30px auto 0;
          cursor: default;
          &:hover, &:active {
            background-color: $primary_color;
          }
        }
      }
    }
  }
}
</style>
