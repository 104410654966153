<template>
  <sally-confirmation-modal name="add-image-modal"
                            class="add-image-modal" height="233">
    <template v-slot:message>
      <div class="message">
        <span>{{ $t('enter_image_url') }}</span>
        <input type="text" class="border" v-model="imageUrl"/>
      </div>
    </template>
    <template v-slot:buttons>
      <button class="secondary" @click="hide">{{ $t('cancel') }}</button>
      <button @click="save">{{ $t('save') }}</button>
    </template>
  </sally-confirmation-modal>
</template>

<script>
import SallyConfirmationModal from '@/components/Common/SallyConfirmationModal.vue';

export default {
  name: 'AddImageModal',
  components: {
    SallyConfirmationModal,
  },
  data() {
    return {
      imageUrl: '',
    };
  },
  methods: {
    save() {
      this.$emit('add', this.imageUrl);
      this.hide();
    },
    hide() {
      this.$modal.hide('add-image-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/variable.scss";

.add-image-modal {
  .message {
    display: flex;
    flex-direction: column;

    input {
      height: 43px;
      margin-top: 10px;
    }
  }
}
</style>
