<template>
  <div class="trial-survey-intro-editor">
    <div class="example-link" @click="onShowModalExample">
      {{ $t('trial_intro_editor.example_link') }}
    </div>
    <div class="box">
      <input
        v-model="trialSurvey.tempTitle"
        :placeholder="$t('trial_intro_editor.placeholder_input')"/>
      <sally-text-editor :heading1="false" :placeholder="placeholderText"
                         :temp="trialSurvey.tempDescription" @update="updateContent"/>
    </div>
    <div class="button-group">
      <button class="next" :disabled="!trialSurvey.tempTitle"
              @click="nextStage">
        <span>{{ $t('next') }}</span>
        <img class="next-image" src="~@/assets/images/ico_btn_next.svg"/>
      </button>
    </div>
    <trial-survey-intro-example-modal/>
    <trial-survey-intro-example-s-r-m-modal/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TrialSurveyIntroExampleModal from '@/components/Modal/TrialSurveyIntroExampleModal.vue';
import TrialSurveyIntroExampleSRMModal from '@/components/Modal/TrialSurveyIntroExampleSRMModal.vue';
import SallyTextEditor from '@/components/Common/SallyTextEditor.vue';
import { progressType } from '@/assets/js/code';
import { SURVEY_ID_SRM } from '@/common/config';

export default {
  name: 'SurveyIntro',
  components: {
    TrialSurveyIntroExampleModal,
    TrialSurveyIntroExampleSRMModal,
    SallyTextEditor,
  },
  data() {
    return {
      title: '',
      placeholderText: `<p>${ this.$t('trial_intro_editor.placeholder_text_editor1') }</p>
        <p><br></p>
        <p>${ this.$t('trial_intro_editor.placeholder_text_editor2') }</p>
        <p>${ this.$t('trial_intro_editor.placeholder_text_editor3') }</p>`,
      tempDescription: '',
    };
  },
  computed: {
    ...mapGetters(['trialSurvey']),
    isDelivery() {
      return ![progressType.CREATING, progressType.TEMP_CREATING_DONE]
        .includes(this.trialSurvey.progress);
    },
  },
  methods: {
    ...mapActions(['modifyTempTitleAndDescription']),
    onShowModalExample() {
      if (this.trialSurvey.id !== SURVEY_ID_SRM) {
        this.$modal.show('trial-survey-intro-example-modal');
      } else {
        this.$modal.show('trial-survey-intro-example-srm-modal');
      }
    },
    updateContent(html) {
      this.tempDescription = html;
    },
    async nextStage() {
      if (this.tempDescription) {
        this.trialSurvey.tempDescription = this.tempDescription;
      }
      await this.modifyTempTitleAndDescription({
        id: this.trialSurvey.id,
        tempTitle: this.trialSurvey.tempTitle,
        tempDescription: this.trialSurvey.tempDescription,
      });
      this.$emit('next');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.trial-survey-intro-editor {
  display: flex;
  flex-direction: column;
  align-items: center;

  .example-link {
    align-self: flex-end;
    text-decoration: underline;
    cursor: pointer;
    text-align: right;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .box {
    width: 100%;
    border: solid 1px #dadce0;

    input {
      width: 100%;
      height: 60px;
      border: none;
      text-align: center;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: normal;
    }

    .sally-text-editor {
      margin-top: 20px;
      width: 100%;
      padding: 0 48px;
      min-height: 620px;
    }
  }

  .button-group {
    display: flex;
    flex-direction: row;
    margin-top: 17px;

    .before {
      margin-right: 18px;
    }

    .next {
      .next-image {
        margin-left: 10px;
        transform: rotate(90deg);
      }
    }
  }
}
</style>
