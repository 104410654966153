<template>
<div class="sally-text-editor">
  <div class="editor">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar">
        <button class="menubar__button" :class="{ 'is-active': isActive.paragraph() }"
          @click="commands.paragraph">
          <div class="paragraph icon" />
        </button>
        <button v-if="heading1"
          class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })">
          <div class="heading1 icon" />
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })">
          <div class="heading2 icon" />
        </button>
        <button class="menubar__button lline" :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold">
          <div class="bold icon" />
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic">
          <div class="italic icon" />
        </button>
        <button class="menubar__button rline" :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline">
          <div class="underline icon" />
        </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list">
          <div class="listitem icon" />
        </button>
        <button class="menubar__button" @click="showAddImageModal(commands.image)">
          <div class="image icon" />
        </button>
      </div>
    </editor-menu-bar>
    <editor-menu-bubble class="menububble" :editor="editor" @hide="hideLinkMenu"
      v-slot="{ commands, isActive, getMarkAttrs, menu }">
      <div
        class="menububble"
        :class="{ 'is-active': menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`">
        <form v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
          <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
          <button class="menububble__button close"
            @click="setLinkUrl(commands.link, null)" type="button" style="min-width:18px">
            <img src="~@/assets/images/close-nero-grey.svg" />
          </button>
        </form>
        <template v-else>
          <button
            class="menububble__button"
            @click="showLinkMenu(getMarkAttrs('link'))"
            :class="{ 'is-active': isActive.link() }">
            <img src="~@/assets/images/ico_editor_hyperlink_white.svg" />
            <span>{{ isActive.link() ? $t('text_editor.edit_link') : $t('text_editor.add_link') }}</span>
          </button>
        </template>
      </div>
    </editor-menu-bubble>
    <editor-content  class="editor__content" :editor="editor" />
  </div>
  <add-image-modal @add="setImageUrl"/>
</div>
</template>

<script>
import {
  Editor, EditorContent, EditorMenuBar, EditorMenuBubble,
} from 'tiptap';
import {
  Heading,
  Bold,
  Italic,
  Underline,
  ListItem,
  BulletList,
  Image,
  Link,
} from 'tiptap-extensions';
import AddImageModal from '@/components/Modal/AddImageModal.vue';

export default {
  name: 'SallyTextEditor',
  components: {
    EditorContent,
    EditorMenuBar,
    EditorMenuBubble,
    AddImageModal,
  },
  props: {
    temp: String,
    heading1: {
      type: Boolean,
      default() {
        return true;
      },
    },
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      content: this.placeholder || `
        <p>${ this.$t('text_editor.enter_content') }</p>
        <p><br></p>
        <p>${ this.$t('text_editor.separator') }</p>
        <p>${ this.$t('text_editor.change_location') }</p>
        <p><br></p>
        <p>${ this.$t('text_editor.button_go_to') }</p>
        `,
      editor: new Editor({
        extensions: [
          new Heading({ levels: [1, 2, 3] }),
          new Bold(),
          new Italic(),
          new Underline(),
          new ListItem(),
          new BulletList(),
          new Image(),
          new Link(),
        ],
        onUpdate: ({ getHTML }) => {
          this.$emit('update', getHTML());
        },
      }),
      linkUrl: null,
      linkMenuIsActive: false,
      command: null,
    };
  },
  mounted() {
    this.content = this.temp || this.content;
    this.editor.setContent(this.content);
    this.$emit('update', this.content);
    this.editor.focus();
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    showAddImageModal(command) {
      this.command = command;
      this.$modal.show('add-image-modal');
    },
    setImageUrl(imageUrl) {
      if (imageUrl !== null) {
        this.command({
          src: imageUrl,
          style: {
            width: '100px',
            height: '100px',
          },
        });
        this.command = null;
      }
    },
    async showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    setLinkUrl(command, url) {
      command({ href: url });
      this.hideLinkMenu();
    },
  },
  watch: {
    temp() {
      this.content = this.temp || this.content;
      this.editor.setContent(this.content);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/style/variable.scss";
.sally-text-editor {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
}
</style>
<style lang="scss">
@import "~@/assets/style/components/sally-text-editor.scss";
</style>
