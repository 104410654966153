<template>
  <modal :name="name" class="sally-confirmation-modal" :clickToClose="false"
    :width="width" :height="height"
    @opened="e => $emit('opened', e)"
    @before-open="e => $emit('before-open', e)">
    <div class="header">
      <img src="~@/assets/images/sally-logo-full.svg" />
    </div>
    <div class="body">
      <div class="message"><slot name="message">message</slot></div>
      <div class="button-group"><slot name="buttons"></slot></div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'SallyConfirmationModal',
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default() {
        return '529';
      },
    },
    height: {
      type: String,
      default() {
        return '180';
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/variable.scss";

.sally-confirmation-modal {
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    background-color: #f7f8fa;
    padding: 0 18px;
    img {
      width: 52px;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 18px;
    .message {
      font-size: $font_size_16;
      line-height: 1.38;
      letter-spacing: normal;
      color: #242424;
      width: 100%;
    }
    .button-group {
      display: flex;
      flex-direction: row;
      margin-top: 24px;
    }
  }
}
</style>
<style lang="scss">
@import '~@/assets/style/components/sally-modal.scss';
.vm--modal {
  border: none;
}
.body {
  .button-group {
    button {
      margin-right: 18px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
