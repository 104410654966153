<template>
  <modal name="trial-survey-intro-example-modal" class="trial-survey-intro-example-modal"
    :width="1040" :height="800">
    <div class="scroll">
      <div class="wrapper">
        <img src="~@/assets/images/close-black.svg" class="close"
            @click="$modal.hide('trial-survey-intro-example-modal')"/>
        <div class="header">
          <div>Title</div>
        </div>
        <div class="body">
          <span class="body-first">Image</span>
        </div>
        <div class="footer">
          <div class="text">
            <span>SDS는 DT(Digital Transformation) Assessment Tool을 가지고</span>
            <span>고객사의 DT 수준을 측정하는 서비스를 제공하고 있습니다.</span>
            <span>DT Assessment Tool 중에서 DRC(Digital Readiness Check)는</span>
            <span>기업 전반적인 디지털 역량을 점검하는 Tool로서, 업종 공통으로 적용할 수 있습니다.</span>
          </div>
          <div class="text">
            <span>DRC 진단설문은 대상/제공서비스에 따라 Trial 버전과 Detail 버전으로 구분되어 있습니다.</span>
            <span>그리고 각 설문에 대한 진단결과는 설문사이트에서 확인할 수 있습니다.</span>
            <span>- Trial 버전 (10여개 설문) : 일반 고객(개인/기업) 대상 DRC Tool 기능/경험 제공</span>
            <span>- Detail 버전 (80여개 설문) : DT 컨설팅 고객 대상 DRC 상세진단/분석리포트 제공</span>
          </div>
          <button class="large">설문 시작하기</button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'TrialSurveyIntroExampleModal',
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';
@import '~@/assets/style/components/sally-modal.scss';

.trial-survey-intro-example-modal {
  .scroll {
    overflow: auto;
    height: 100%;
    width: 100%;
    .wrapper {
      position: relative;
      width: 100%;
      padding: 48px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .close {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
      }
      .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: $font_size_32;
        font-weight: 500;
        line-height: 1.34;
        letter-spacing: normal;
        color: #2a324b;
      }
      .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 50px;
        border: solid 1px #707070;
        width: 600px;
        height: 260px;
        line-height: 1.34;
        letter-spacing: normal;
        color: #acacac;
        .body-first {
          font-size: $font_size_32;
          font-weight: $font_weight_400;
        }
      }
      .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
          font-size: $font_size_16;
          font-weight: $font_weight_400;
          letter-spacing: normal;
          color: #2a324b;
        }
        button {
          cursor: default;
          &:hover, &:active {
            background-color: $primary_color;
          }
        }
      }
    }
  }
}
</style>
