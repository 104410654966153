<template>
  <div class="editor">
    <div class="scroll">
      <div class="wrapper">
        <div class="inner">
          <navigator :stage="currentStage"/>
          <trial-survey-intro-editor @before="beforeStage" @next="nextStage"
            v-if="currentStage === 'STAGE1'" class="page"/>
          <trial-survey-upload @before="beforeStage" @next="nextStage"
            v-if="currentStage === 'STAGE2'" class="page"/>
          <delivery v-if="currentStage === 'STAGE3'" class="page mt-48" @move="moveStage"/>
        </div>
        <copyright />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Navigator from '@/components/Trial/Navigator.vue';
import Copyright from '@/components/Common/Copyright.vue';
import TrialSurveyIntroEditor from '@/views/StepComponent/TrialSurveyIntroEditor.vue';
import TrialSurveyUpload from '@/views/StepComponent/SurveyUpload.vue';
import Delivery from '@/views/StepComponent/Delivery.vue';
import { progressType } from '@/assets/js/code';

export default {
  name: 'editor',
  components: {
    Navigator,
    Copyright,
    TrialSurveyIntroEditor,
    TrialSurveyUpload,
    Delivery,
  },
  data() {
    return {
      currentStage: 'STAGE1', // STAGE1, STAGE2, STAGE3
      progressType,
    };
  },
  computed: {
    ...mapGetters(['trialSurvey']),
  },
  async created() {
    await this.fetchTrialSurvey();
    if (!this.trialSurvey.id) {
      await this.saveTrialSurvey();
    }
    if (this.trialSurvey.progress !== progressType.CREATING) {
      this.currentStage = 'STAGE3';
    }
    // TODO: 배포되지 않고 1Page만 저장되었을 때 로직
  },
  destroyed() {
    this.clearTrialSurvey();
  },
  methods: {
    ...mapActions(['fetchTrialSurvey', 'saveTrialSurvey', 'modifyTrialProgress']),
    ...mapMutations(['clearTrialSurvey']),
    beforeStage() {
      if (this.currentStage === 'STAGE2') {
        this.currentStage = 'STAGE1';
      } else if (this.currentStage === 'STAGE3') {
        this.currentStage = 'STAGE2';
      }
    },
    async nextStage() {
      if (this.currentStage === 'STAGE1') {
        this.currentStage = 'STAGE2';
      } else if (this.currentStage === 'STAGE2') {
        this.currentStage = 'STAGE3';
        if (this.trialSurvey.progress === this.progressType.CREATING) {
          await this.modifyTrialProgress({
            id: this.trialSurvey.id,
            progress: this.progressType.TEMP_CREATING_DONE,
          });
        }
      }
    },
    moveStage(stage) {
      this.currentStage = stage;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';
.mt-48 {
  margin-top: 48px;
}
.editor {
  width: 100%;
  min-width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  .scroll {
    width: 100%;
    height: 100%;
    overflow: auto;
    .wrapper {
      padding: $standard_padding_size 0 0 0;
      max-width: $max_width;
      margin: auto;
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        .page {
          width: 100%;
        }
        .button-group {
          display: flex;
          flex-direction: row;
          margin-top: 17px;
          .before {
            margin-right: 18px;
          }
          .next {
            .next-image {
              margin-left: 10px;
              transform: rotate(90deg);
            }
          }
        }
      }
      .copyright {
        padding-top: $standard_padding_size;
      }
    }
  }
}
</style>
