<template>
  <div class="delivery">
    <div class="inner">
      <div class="column">
        <div class="box">
          <span class="box-text">{{ $t('delivery.description') }}</span>
          <button class="green" @click="moveToStage1">{{ $t('delivery.edit') }}</button>
        </div>
        <div class="box upload">
          <span class="box-text">{{ $t('delivery.upload') }}</span>
          <button class="green" @click="moveToStage2">{{ $t('delivery.edit') }}</button>
        </div>
      </div>
      <div class="button-group">
        <button v-if="!isDelivery"
                class="create" @click="deploy" :disabled="isClicked">
          {{ $t('delivery.create_trial') }}
        </button>
        <button v-else
                class="recreate warning" @click="redeploy" :disabled="!isChanged">
          {{ $t('delivery.reset') }}
        </button>
      </div>
    </div>
    <div v-if="isTrialLoading" class="url-box loading">
      <img src="~@/assets/images/sally-logo-icon.svg"/>
      <span>{{ $t('delivery.creating') }}</span>
    </div>
    <div v-if="isDelivery && !isTrialLoading" class="url-box">
      <div class="url-inner">
        <div class="url">{{ surveyUrl }}</div>
        <button class="copy" v-clipboard:copy='surveyUrl' @click="showToast">
          {{ $t('delivery.button_copy') }}
        </button>
      </div>
      <div class="url-time">
        <span>{{ $t('delivery.create_date') }} {{ createdDate }}</span>
        <span v-if="updatedDate" class="updated">| {{ $t('delivery.last_date') }} {{ updatedDate }} </span>
      </div>
      <div class="url-inner">
        <div class="url">{{ fpSurveyUrl }}</div>
        <button class="copy" v-clipboard:copy='fpSurveyUrl' @click="showToast">
          {{ $t('delivery.button_copy') }}
        </button>
      </div>
      <div class="url-time">
        <span>{{ $t('delivery.create_date') }} {{ createdDate }}</span>
        <span v-if="updatedDate" class="updated">| {{ $t('delivery.last_date') }} {{ updatedDate }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { progressType } from '@/assets/js/code';
import { TRIAL_FRONTEND_URL } from '@/common/config';
import { format } from '@/common/date.util';

export default {
  name: 'Delivery',
  computed: {
    ...mapGetters(['trialSurvey', 'isTrialLoading', 'isClicked', 'isChanged']),
    surveyUrl() {
      return `${TRIAL_FRONTEND_URL}/survey/${this.trialSurvey.id}`;
    },
    fpSurveyUrl() {
      return `${TRIAL_FRONTEND_URL}/fp/${this.trialSurvey.id}`;
    },
    createdDate() {
      return format(this.trialSurvey.createdDate, 'YYYY.MM.DD HH:mm:ss');
    },
    updatedDate() {
      return format(this.trialSurvey.updatedDate, 'YYYY.MM.DD HH:mm:ss');
    },
    isDelivery() {
      return ![progressType.CREATING, progressType.TEMP_CREATING_DONE]
        .includes(this.trialSurvey.progress);
    },
  },
  methods: {
    ...mapActions(['modifyTrialSurvey']),
    ...mapMutations(['setIsClicked', 'setIsCompletedTimer', 'setIsCompletedSaveData', 'setIsChanged', 'setTrialSurvey']),
    async updateTrialSurvey() {
      this.beforeSave();
      const result = await Promise.all([
        this.modifyTrialSurvey({
          id: this.trialSurvey.id,
          tempTitle: this.trialSurvey.tempTitle,
          tempDescription: this.trialSurvey.tempDescription,
        }),
        this.setTimeout(),
      ]);
      this.setTrialSurvey(result[0]);
      this.afterSave();
    },
    async deploy() {
      await this.updateTrialSurvey();
    },
    async redeploy() {
      await this.updateTrialSurvey();
      this.setIsChanged(false);
    },
    beforeSave() {
      this.setIsClicked(true);
      this.setIsCompletedTimer(false);
      this.setIsCompletedSaveData(false);
    },
    afterSave() {
      this.setIsClicked(false);
      this.setIsCompletedTimer(true);
      this.setIsCompletedSaveData(true);
    },
    setTimeout() {
      return new Promise((resolve) => {
        window.setTimeout(() => {
          resolve();
        }, 2000);
      });
    },
    moveToStage1() {
      this.setIsChanged(true);
      this.$emit('move', 'STAGE1');
    },
    moveToStage2() {
      this.setIsChanged(true);
      this.$emit('move', 'STAGE2');
    },
    showToast() {
      this.$toasted.show(`
        <div class="sally-trial-toast">
          <div class="image"></div>
          <span>${ this.$t('delivery.toast_copied') }</span>
        </div>
      `, {
        position: 'top-center',
        duration: 3000,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/variable.scss";

.delivery {
  max-width: 538px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 78px;
    border: solid 1px #dadce0;

    .column {
      width: 100%;

      .box {
        width: 100%;
        height: 48px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-right: 21px;
        padding-left: 24px;
        margin-bottom: 24px;
        border-radius: 3px;
        background-color: rgba(0, 202, 148, 0.1);
        font-size: $font_size_16;
        font-weight: 500;
        letter-spacing: normal;
        text-align: left;
        color: #2a324b;

        &.upload {
          margin-bottom: 0;
        }

        .green {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          font-size: $font_size_16;
          font-weight: 500;
          letter-spacing: normal;
          text-align: left;
          background-color: transparent;
          color: $primary_color;
          cursor: pointer;
        }

        .box-text {
          cursor: default;
        }

        // &.disabled {
        //   background-color: rgba(42, 50, 75, 0.2);
        //   .green:disabled {
        //     cursor: default;
        //     color: #ffffff;
        //     background-color: transparent;
        //   }
        //   .box-text {
        //     color: #ffffff;
        //   }
        // }
      }
    }

    .button-group {
      display: flex;
      flex-direction: row;
      margin-top: 36px;

      button {
        margin-right: 18px;

        &:last-child {
          margin-right: 0;
        }
      }

      .create {
        width: 172px;
      }

      .recreate {
        width: 260px;
      }
    }
  }

  .url-box {
    width: 100%;
    padding: 20px 32px 10px 32px;
    border-bottom: solid 1px #dadce0;
    border-left: solid 1px #dadce0;
    border-right: solid 1px #dadce0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.loading {
      display: flex;
      flex-direction: row;
      padding: 30px 32px;

      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
    }

    .url-inner {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 32px;
      width: 100%;

      .url {
        flex: 1;
        font-size: $font_size_16;
        color: #707070;
        border: solid 1px #dadce0;
        line-height: 32px;
        padding-left: 12px;
        padding-right: 6px;
        letter-spacing: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .copy {
        width: 94px;
      }
    }

    .url-time {
      font-size: $font_size_12;
      line-height: 1.42;
      letter-spacing: normal;
      color: #acacac;
      margin-bottom: 10px;
      align-self: flex-start;

      .updated {
        padding-left: 3px;
      }
    }
  }
}
</style>
<style lang="scss">
.toasted-container .toasted {
  background-color: #fff;
  top: 50%;

  .sally-trial-toast {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    letter-spacing: normal;
    color: #2a324b;

    .image {
      width: 20px;
      height: 20px;
      background-image: url("~@/assets/images/check-green-round.svg");
      background-size: 20px 20px;
      background-position: 0 0;
      margin-right: 8px;
    }
  }
}

.toasted-container.top-center {
  top: calc(50% + 300px);
}
</style>
